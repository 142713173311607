<template>
  <div class="container">
    <div class="row">
      <div class="col-12 p-3">
        <dx-util-popup
          ref="planFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          content-template="content"
          title="Plan Form"
          position="center"
          width="600"
          height="auto"
          :on-hiding="onHiding"
        >
          <template #content>
            <dx-util-form ref="planFormRef" :form-data="plan" label-location="top">
              <dx-util-simple-item data-field="planName">
                <dx-util-required-rule message="Plan name is required" />
              </dx-util-simple-item>
              <dx-util-group-item :col-count="3">
                <dx-util-simple-item data-field="itemFee" editor-type="dxNumberBox">
                  <dx-util-required-rule message="Item fee is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="min" editor-type="dxNumberBox">
                  <dx-util-required-rule message="Minimum count is required" />
                  <dx-util-range-rule message="Minimum count can not be negative number" :min="0" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="max" editor-type="dxNumberBox">
                  <dx-util-required-rule message="Maximum count is required" />
                  <dx-util-range-rule message="Maximum count can not be negative number" :min="0" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2">
                <dx-util-simple-item data-field="planCategory" editor-type="dxSelectBox" :editor-options="planCategoryEditorOptions">
                  <dx-util-required-rule message="Plan category is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="planType" editor-type="dxSelectBox" :editor-options="planTypeEditorOptions">
                  <dx-util-required-rule message="Plan type is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2">
                <dx-util-simple-item data-field="discount" editor-type="dxNumberBox">
                  <dx-util-required-rule message="Discount is required" />
                  <dx-util-range-rule message="Discount can not be negative number" :min="0" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="discountExp" editor-type="dxDateBox">
                  <dx-util-required-rule message="Discount expiration date is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2">
                <dx-util-simple-item data-field="status" editor-type="dxSelectBox" :editor-options="statusEditorOptions" />
                <dx-util-simple-item data-field="visibility" editor-type="dxSelectBox" :editor-options="visibilityEditorOptions" />
              </dx-util-group-item>
              <dx-util-simple-item data-field="warehouse" editor-type="dxSelectBox" :editor-options="warehouseEditorOptions">
                <dx-util-required-rule message="Warehouse is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="description" editor-type="dxTextArea" :editor-options="{height: 90}" />
            </dx-util-form>
          </template>
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
        </dx-util-popup>
      </div>
    </div>
  </div>
</template>

<script>
import customerPlanStatusEnum, { getPlanStatusOptions } from '@/enums/customerPlanStatus.enum'
import customerPlanVisibilityEnum, { getCustomerPlanVisibilityOptions } from '@/enums/customerPlanVisibility.enum'
import { getCustomerPlanCategoryOptions } from '@/enums/customerPlanCategory.enum'
import planManagementService from '@/http/requests/plan/planManagementService'

const plan = {
  id: '',
  planName: '',
  planCategory: '',
  planType: '',
  itemFee: null,
  min: null,
  max: null,
  discount: null,
  discountExp: null,
  status: customerPlanStatusEnum.ACTIVE.value,
  visibility: customerPlanVisibilityEnum.PUBLIC.value,
  warehouse: '',
  description: '',
}
export default {
  props: {
    warehouses: {
      type: Array,
      default: () => [],
      required: true,
    },
    planTypes: {
      type: Array,
      default: () => [],
      required: true,
    },
    showPopup: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      plan,
    }
  },
  computed: {
    warehouseOptions() {
      return this.warehouses.filter(el => el.value !== 0)
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        onClick: this.handleSubmit,
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.$refs.planFormPopupRef.instance.hide()
          this.$emit('form-closed')
        },
      }
    },
    planTypeEditorOptions() {
      return {
        items: this.planTypesWithoutAllOption,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    planCategoryEditorOptions() {
      return {
        items: getCustomerPlanCategoryOptions(),
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    statusEditorOptions() {
      return {
        items: getPlanStatusOptions(),
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    visibilityEditorOptions() {
      return {
        items: getCustomerPlanVisibilityOptions(),
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    warehouseEditorOptions() {
      return {
        items: this.warehouseOptions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    planTypesWithoutAllOption() {
      return this.planTypes.filter(el => el.value !== 0)
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue) {
          this.$refs.planFormPopupRef.instance.show()
        } else {
          this.$refs.planFormPopupRef.instance.hide()
        }
      },
      immediate: true,
    },
    currentRow: {
      handler(newValue) {
        if (newValue.id) {
          const planId = this.planTypesWithoutAllOption.filter(el => el.text === newValue.planType)[0].value
          const visibilityValue = newValue.visibility === 'Public' ? 0 : 1
          // const warehouseNameValue = this.warehouses.filter(el => el.text === newValue.warehouseName)[0].value
          let warehouseNameValue = ''
          if (newValue.warehouseName !== 'Common') {
            warehouseNameValue = this.warehouseOptions.filter(el => el.text === newValue.warehouseName)[0].value
          }

          this.plan.description = newValue.description
          this.plan.discount = newValue.discount
          this.plan.discountExp = newValue.discountExp
          this.plan.id = newValue.id
          this.plan.itemFee = newValue.itemFee
          this.plan.max = newValue.maxItemCount
          this.plan.min = newValue.minItemCount
          this.plan.planCategory = newValue.planCategory
          this.plan.planName = newValue.planName
          this.plan.planType = planId
          this.plan.status = newValue.status
          this.plan.visibility = visibilityValue

          this.plan.warehouse = warehouseNameValue
        } else if (this.warehouseOptions.length === 1) {
          this.plan.warehouse = this.warehouseOptions[0].value
        }
      },
      immediate: true,
    },
  },
  mounted() {

  },
  methods: {
    async handleSubmit() {
      const result = await this.$refs.planFormRef.instance.validate()
      if (result.isValid) {
        if (this.plan.id) {
          await planManagementService.updatePlan(this.plan)
        } else {
          await planManagementService.createPlan(this.plan)
        }
        this.$emit('form-saved')
      }
    },
    onHiding() {
      this.$refs.planFormRef.instance.resetValues()
      this.plan.id = null
      this.$refs.planFormPopupRef.instance.hide()
      this.$emit('form-closed')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
